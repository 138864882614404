<template>
    <div role="main" class="main">
      <section class="together_we_join minh-custom with-footer speaker-room-page">
        <div class="col-12 text-right">
          <b-button type="button" class="btn-primary mr-2" @click="goLive" :disabled="event.status == 1"
            >Start Service</b-button
          >
          <b-button type="button" class="btn-primary" @click="endservice"
            >End Service</b-button
          >
        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-2">
             
                <div v-if="current_speaker != null && display_close">
                  <div class="d-flex flex-row-reverse">
                    <b-button
                      type="button"
                      class="btn-primary"
                      @click="closeInfoSpeaker(current_speaker)"
                      >X</b-button>
                  </div>
                  <div class="profile-pic" >
                  <img
                    :src="current_speaker.profile_url"
                    class="mr-2 mb-1 live_profile_img"
                  />
                  {{ current_speaker.speaker_name }}
                </div>
                <h6 v-if="current_speaker != null"
                  style="max-height: calc(100vh - 59vh); overflow-y: auto"
                  class="text-primary text-left mt-3"
                  
                >
                  {{ current_speaker.bio }}
                </h6>
              </div>
            </div>
            <div class="col-md-9">
              <div>
                <!-- <iframe
                  style="
                    height: 632px;
                    width: 100%;
                    padding: 25px 19px;
                    z-index: 9;
                    position: relative;
                  "
                  :src="host_room_url"
                  allow="camera;speaker; microphone;display-capture; fullscreen;autoplay; encrypted-media"
                ></iframe> -->
                <!-- <iframe
                  style="
                    height: 632px;
                    width: 100%;
                    padding: 25px 19px;
                    z-index: 9;
                    position: relative;
                   src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e"
                   allow="camera;speaker; microphone;display-capture; fullscreen;autoplay; encrypted-media"
                ></iframe> -->
                <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e" allow="autoplay" allowfullscreen frameborder="0" style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
        </div>
      </section>

      <footer class="container">
        <div class="row align-items-center">
          <transition name="pop" appear>
            <div
              class="custom-modal note-modal"
              v-if="show_modal"
              id="EulogyNotes"
              tabindex="-1"
              role="dialog"
              aria-labelledby="Eulogy Notes"
              aria-hidden="true"
              v-draggable="draggableValue"
              style="z-index: 9"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-body" style="background-color: black">
                    <div class="row">
                      <div
                        class="col-lg-12"
                        style="justify-content: space-between; display: flex"
                      >
                        <h5 class="text-custom text-left">
                          <img
                            :src="require('@/assets/img/move_icon.png')"
                            :ref="handleId"
                          />Notes
                        </h5>
                        <b-button
                          type="button"
                          class="btn-primary mr-2"
                          @click="closemodel()"
                          >X</b-button
                        >
                      </div>
                      <div
                        class="col-lg-12 text-custom mt-4 scroll-notes"
                        style="max-height: 200px"
                      >
                        <p class="m-0">{{ speaker_notes }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div class="col-md-7">
            <div class="participate speaker_list">
              <ul class="list-group list-group-horizontal-md flex-wrap">
                <li
                  v-for="speaker in speakers"
                  :key="speaker.speaker_id"
                  class="list-group-item py-1 px-0"
                  style="background-color: black; margin-right: 0px"
                >
                  <!-- <div class="videosession"> </div>-->

                  <div style="margin-right: 15px" @click="openpopup(speaker)">
                    <img :src="speaker.profile_url" class="img-fluid" />
                  </div>
                </li>
              </ul>
            </div>
            <div class="d-flex align-items-center" style="height: 130px">
              <div>
                <p class="text-primary text-left pr-2" style="font-size: 24px">
                  LIVE MESSAGES:
                </p>
              </div>
              <div style="width: 325px" class="mx-2">
                <div
                  class="my-auto d-flex align-items-center"
                  v-if="latest_profile_url != '' && latest_profile_name != ''"
                >
                  <div class="profile-pic border-0 mb-2">
                    <img
                      :src="latest_profile_url"
                      class="mr-3"
                      style="width: 80px; height: 80px"
                    />
                  </div>
                  <div>
                    <div
                      class="text-primary text-left mb-0"
                      style="font-size: 22px; font-weight: 600"
                    >
                      {{ latest_profile_name }}
                    </div>
                    <div
                      class="text-primary text-left mb-0"
                      style="overflow-y: auto; max-height: 100px"
                    >
                      {{ latest_message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 text-center text-lg-right" v-if="event">
            <!-- <img
              :src="event.program_url"
              class="img-fluid pointer"
              style="max-height: 110px"
              @click="enlarge()"
            /> -->
            <div class="flower" style="margin-bottom: 0.5rem" v-for="(input,k) in imagesArray" :key="k">
                  <img
                    :src="input.program_url"
                    class="img-fluid"
                    alt="no img"
                    style="height: 100%"
                    @click="enlarge()"
                  />
                </div>
          </div>
        </div>
      </footer>

      <!-- Modal -->
      <transition name="pop" appear>
        <div
          class="modal fade show program-modal"
          v-if="show_program_modal"
          id="myModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModal"
          aria-hidden="true"
          v-draggable="draggableValue"
          @click="closeModal"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeProgramModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="row" v-if="event">
                  <div class="col-lg-12">
                    <div class="flower" style="margin-bottom: 0.5rem" v-for="(input,k) in imagesArray" :key="k">
                  <img
                    :src="input.program_url"
                    class="img-fluid"
                    alt="no img"
                    style="height: 100%"
                  />
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </template>
  <script>
  import { getApiManager } from "../../api";
  import {
    apiBaseUrl,
    admin_key,
    company_wait_prefix,
    liveBaseUrl,
  } from "../../constants/config";
  import { mapActions } from "vuex";
  import { Draggable } from "draggable-vue-directive";

  import adapter from "webrtc-adapter";
  window["adapter"] = adapter;

  export default {
    directives: {
      Draggable,
    },
    data() {
      return {
        draggableValue: {
          handle: undefined,
        },
        handleId: "handle-id",
        speakers: [],
        visitors: [],
        checkTimer: null,
        event: null,
        event_id: null,
        content_type_id: null,
        show_modal: false,
        connection: null,
        devices: [],
        host_room_url: "",
        decease_name: "",
        speaker_notes: "",
        current_speaker: null,
        latest_message: "",
        latest_profile_url: "",
        latest_profile_name: "",
        show_program_modal: false,
        imagesArray:[],
        display_close:false,
      };
    },
    mounted() {
      this.draggableValue.handle = this.$refs[this.handleId];
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
          live: 1,
          content_type_id: this.content_type_id,
        })
        .then((response) => {});
      this.getDetail();
      this.getCondolences();
      // this.checkTimer = setInterval(() => {
      //   this.checkLive()
      // }, 1000 * 10)
      this.connectSocket();
    },
    methods: {
      ...mapActions(["signOut"]),
      connectSocket() {
        var ref = this;

        this.connection = new WebSocket(liveBaseUrl);
        this.connection.onmessage = function (event) {
          //this.isLoading = false;
          let data = JSON.parse(event.data);
          console.log("&&&&&&&&&&&&&&&&&", data);
          if (data.type == "change_order") {
            console.log("44444444444444!!");
            // ref.receiveMoveLive(data.order);
          } else if (data.type == "user_info") {
            console.log("!!!!!!!!!!!!!!!!!!!!");
            ref.connection.send(
              JSON.stringify({
                type: "my_info",
                event_id: ref.event_id,
              })
            );
          } else if (data.type == "end_live") {
            //route after finishes lives
            console.log("77777777777777");
          } else if (data.type == "event_distroy") {
            // localStorage.setItem("Live_id", null);
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            ref.$router.push("/speaker/profile?x=" + randomnumber);
            
            // window.location.reload();
          } else if (data.type == "start_count") {
            console.log("countdown");
            getApiManager().post(`${apiBaseUrl}/api/speaker/event/modify`, {
              status: 2,
            });
          }
        };

        this.connection.onclose = (e) => {
          console.log(
            "Socket is closed. Reconnect will be attempted in 1 second."
          );
          setTimeout(() => {
            ref.connectSocket();
          }, 1000);
        };
      },
      // getEventUrl() {
      //   getApiManager()
      //     .post(`${apiBaseUrl}/api/admin/event/get`)
      //     .then((response) => {
      //       let res = response.data;
      //       if (res.status === true) {
      //         this.host_room_url = res.detail.hostRoomUrl;
      //         console.log("*((((((((((((((", this.host_room_url);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // },
      logout() {
        if (self.OTSession) {
          console.log("&&&&&&&&&&&&&", self.OTSession);
          self.OTSession.disconnect();
        }
        getApiManager()
          .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
            live: 0,
            content_type_id: this.content_type_id,
          })
          .then((response) => {
            localStorage.setItem("Live_id", null);
            localStorage.setItem("deceased_name",'')
            this.signOut();
            this.$router.push("/login");
            window.location.reload();
          });
      },
      receiveMoveLive(order) {
        console.log("hererereererrerere");
        this.connection.close();
        if (order == 1) {
          this.stopLive();
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          this.$router.push("/speaker/start/"+this.event_id+"?x=" + randomnumber);
        } else if (order == 2) {
          this.stopLive();
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          this.$router.push("/speaker/live/"+this.event_id+"?x=" + randomnumber);
        }
      },
      closeModal() {
        this.show_modal = false;
      },
      enlarge() {
        this.show_program_modal = true;
      },
      closeProgramModal() {
        this.show_program_modal = false;
      },
      getCondolences() {
        let ref = this;
        let i = 0;
        getApiManager()
          .post(`${apiBaseUrl}/api/admin/condolence/all`, {
            event_id: this.event_id,
          })
          .then((response) => {
            let res = response.data;
            if (res.status === true) {
              let data = res.data;
              if (data.length > 0) {
                let condolence = {
                  profile_url: data[0].content_type_id
                    ? apiBaseUrl + "/" + data[0].profile_url
                    : apiBaseUrl + "/" + data[0].visitor_profile_url,
                  profile_name: data[0].content_type_id
                    ? data[0].speaker_name
                    : data[0].full_name,
                  messages: [],
                };
                condolence.messages.push(data[0].message);
                this.latest_message = data[i].message;
                this.latest_visitor_id = data[i].content_type_id
                  ? data[i].content_type_id
                  : data[i].visitor_id;
                this.latest_profile_url = data[i].content_type_id
                  ? apiBaseUrl + "/" + data[i].profile_url
                  : apiBaseUrl + "/" + data[i].visitor_profile_url;
                this.latest_profile_name = data[i].content_type_id
                  ? data[i].speaker_name
                  : data[i].full_name;

                setInterval(() => {
                  i++;
                  if (i == data.length) {
                    i = 0;
                  }
                  this.latest_message = data[i].message;
                  this.latest_visitor_id = data[i].content_type_id
                    ? data[i].content_type_id
                    : data[i].visitor_id;
                  this.latest_profile_url = data[i].content_type_id
                    ? apiBaseUrl + "/" + data[i].profile_url
                    : apiBaseUrl + "/" + data[i].visitor_profile_url;
                  this.latest_profile_name = data[i].content_type_id
                    ? data[i].speaker_name
                    : data[i].full_name;
                }, 30000);
              }
            }
          })
          .catch((error) => {});
      },
      openpopup(speaker) {
        this.show_modal = false;
        this.show_modal = true;
        this.display_close= true;
        this.current_speaker = speaker;
        this.speaker_notes = speaker.notes;
        console.log("&&&&&&&&&&&&&&&&");
        this.connection.send(
          JSON.stringify({
            type: "change_speaker_bio",
            speaker: speaker,
            isClose:false
          })
        );
      },
      closeInfoSpeaker(speaker){
        this.display_close= false;
        this.show_modal = false;
        this.current_speaker = "";
        this.speaker_notes = "";
        this.connection.send(
          JSON.stringify({
            type: "change_speaker_bio",
            speaker: speaker,
            isClose:true
          })
        );
      },
      closemodel() {
        this.show_modal = false;
      },
      changeSpeakerVideoStatus(index, status) {
        for (var i = 0; i < this.speakers.length; i++) {
          if (this.speakers[i].content_type_id == index) {
            this.speakers[i].video_status = status;
          }
        }
        this.$forceUpdate();
      },
      deviceList() {
        var videoDevices = this.room.listVideoDevices();
        var devices = [];
        videoDevices.forEach(function (device) {
          var label = device.label;
          if (!label || label === "") label = device.deviceId;

          if (device.kind === "videoinput") {
            //console.log("videoinput***"+option);
            // var option = document.createElement("option");
            // option.text = label;
            // option.value = device.deviceId;
            // selectElement.appendChild(option);
            devices.push(device);
          }
        });

        this.devices = devices;
      },
      onLocalJoin() {
        console.log("inside onlocalJoin");

        if (
          document.getElementById("selectCamera_" + this.content_type_id) !== null
        ) {
          console.log("inside selectCamera_");
          document
            .getElementById("selectCamera_" + this.content_type_id)
            .remove();
        }

        let target = document.getElementById("video_" + this.content_type_id);
        //target.muted = true;
        this.room.attachStream(target, 0);
        //target.volume = 0;
        this.changeSpeakerVideoStatus(this.content_type_id, true);
        let buttonTarget = document.getElementById(
          "button_" + this.content_type_id
        );
        buttonTarget.hidden = true;
        var videoDevices = this.room.listVideoDevices();
        console.log("we got the devices****" + this.room.listVideoDevices());
        let selectCameraTarget = document.getElementById(
          "divCamera_" + this.content_type_id
        );
        selectCameraTarget.hidden = false;
      },
      onRemoteJoin(index, remoteUsername, feedId) {
        let splits = remoteUsername.split("speaker_");
        if (splits.length == 2) {
          let content_type_id = splits[1];
          let target = document.getElementById("video_" + content_type_id);
          this.room.attachStream(target, index);
          this.changeSpeakerVideoStatus(content_type_id, true);
          //target.muted = false;
          let buttonTarget = document.getElementById("button_" + content_type_id);
          buttonTarget.disabled = false;
          buttonTarget.style.backgroundColor = "#fcd351";
          buttonTarget.addEventListener("click", () => {
            target.muted = false;
            buttonTarget.hidden = true;
          });
        }
      },
      onRemoteUnjoin(index, remoteUsername) {
        let splits = remoteUsername.split("speaker_");
        if (splits.length == 2) {
          let content_type_id = splits[1];
          this.changeSpeakerVideoStatus(content_type_id, false);
        }
      },
      onMessage(data) {
        console.log(data);
      },
      onError(err) {
        console.log(err);
      },
      registerUserName() {
        this.room.register({
          username: "speaker_" + this.content_type_id,
          room: parseInt(company_wait_prefix) + this.event_id,
        });
      },
      createRoom() {
        var ref = this;
        this.room
          .createRoom({
            room: parseInt(company_wait_prefix) + this.event_id,
            publishers: 100,
            admin_key: admin_key,
            videocodec: "h264",
            h264_profile: "42e01f",
          })
          .then(() => {
            setTimeout(function () {
              ref.registerUserName();
            }, 1000);
          })
          .catch((err) => {
            alert(err);
          });
      },
      stopLive() {
        this.room.unpublishOwnFeed();
      },
      switchCamera(event) {
        var cameraId = event.target.id.substring(
          event.target.id.indexOf("_") + 1
        );
        console.log("You selected: " + cameraId);
        var options = {
          username: "speaker_" + this.content_type_id,
          room: parseInt(company_wait_prefix) + this.event_id,
        };
        this.room.switchCamera(cameraId, options);
        //document.getElementById('selectCamera_' + this.content_type_id).remove();
      },

      checkLive() {
        getApiManager()
          .post(`${apiBaseUrl}/api/speaker/event/get`)
          .then((response) => {
            let res = response.data;
            if (res.status === true) {
              let detail = res.detail;
              if (detail.status == 1) {
                clearInterval(this.checkTimer);
                this.stopLive();
                let randomnumber = Math.floor(Math.random() * 10000) + 1;
                this.$router.push("/speaker/start/"+this.event_id+"?x=" + randomnumber);
              } else if (detail.status == 2) {
                clearInterval(this.checkTimer);
                this.stopLive();
                let randomnumber = Math.floor(Math.random() * 10000) + 1;
                this.$router.push("/speaker/live/"+this.event_id+"?x=" + randomnumber);
              }
            }
          })
          .catch((error) => {});
      },
      getDetail() {
        this.event_id = JSON.parse(localStorage.getItem("loginInfo"))
          ? JSON.parse(localStorage.getItem("loginInfo")).event_id
          : 0;
        this.content_type_id = JSON.parse(localStorage.getItem("loginInfo"))
          ? JSON.parse(localStorage.getItem("loginInfo")).id
          : 0;
        if (
          this.event_id == null ||
          (this.event_id == "" && this.event_id == undefined)
        ) {
          this.event_id = localStorage.getItem("event_id");
        }
        var ref = this;
        getApiManager()
          .post(`${apiBaseUrl}/api/visitor/event/getEventDetail`, {
            event_id: this.event_id,
          })
          .then((response) => {
            console.log("(((((((((((((((((((", response);
            let res = response.data;
            this.event = res.data[0];
            getApiManager()
            .post(`${apiBaseUrl}/api/admin/event/getuploadImagebyEventId`, {
              event_id: this.event_id,
            })
            .then((resp) => {
              console.log("777777777",resp.data)
                this.imagesArray=resp.data.detail;
                if(this.imagesArray && this.imagesArray.length>0){
                for(var i=0;i<this.imagesArray.length;i++){
                  this.imagesArray[i].program_url=`${apiBaseUrl}` + "/" + this.imagesArray[i].program_url
                }
                this.inputs=this.imagesArray
              }
            })
            // this.event.program_url = apiBaseUrl + "/" + res.data[0].program_url;
            this.decease_name = response.data.data[0].decease_name;
            console.log("^^^^^^^^^^^^^^^", this.decease_name);
            localStorage.setItem("decease_name", this.decease_name);
            this.host_room_url = response.data.data[0].hostRoomUrl;
            this.event_id = response.data.data[0].event_id;
            if (res.status === true) {
              getApiManager()
                .post(`${apiBaseUrl}/api/speaker/event/getSpeakerByEventid`, {
                  event_id: this.event_id,
                })
                .then((response) => {
                  let res = response.data;
                  if (res.status === true) {
                    ref.speakers = res.detail.speakers;
                    var i;
                    for (i = 0; i < ref.speakers.length; i++) {
                      ref.speakers[i].profile_url =
                        apiBaseUrl + "/" + ref.speakers[i].profile_url;
                      ref.speakers[i].video_status = false;
                    }
                  }
                })
                .catch((error) => {});
            }
          });
      },

      goLive() {
        localStorage.setItem("Live_id", this.content_type_id);
        var ref = this;

        getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/modifyEventAdmin`, {
            status: 1,
            event_id: this.event_id,
          })
          .then((response) => {
            console.log('response: ', response);
            this.event.status = 1
            let res = response.data;
            if (res.status === true) {
              clearInterval(this.checkTimer);
              //this.stopLive();

              ref.connection.send(
                JSON.stringify({
                  type: "start_count",
                  order: 1,
                })
              );
              // let randomnumber = Math.floor(Math.random() * 10000) + 1;
              // this.$router.push("/speaker/start?x=" + randomnumber);
            }
          })
          .catch((error) => {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
          });
      },
      endservice() {
        getApiManager()
          .post(`${apiBaseUrl}/api/vonage/RemoveSession`, {
            decease_name: this.decease_name,
          })
          .then((result) => {
            console.log("44444444444444", result);
            if (result.data.success == true) {
              getApiManager()
                .post(`${apiBaseUrl}/api/admin/event/modifyEventAdmin`, {
                  status: 3,
                  event_id: this.event_id,
                })
                .then((result) => {
                  let res = result.data;
                  if (res.status === true) {
                    console.log("here");
                    this.connection.send(
                      JSON.stringify({
                        type: "end_live",
                        event_id:this.event_id
                      })
                    );
                    this.$router.push("/admin/event");
                  }
                });
            }
          });
      },
    },
    components: {},
    computed: {},
    watch: {},
  };
  </script>
  <style scoped>
  .participate .list-unstyled {
    display: flex !important;
  }
  #subscriber {
    display: flex !important;
  }
  .participate .text-label {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -8px !important;
    margin-right: 15px;
    top: 0 !important;
    background-color: #00000000 !important;
    position: absolute !important;
  }
  .participate .text-label span {
    background-color: #000 !important;
    left: unset !important;
    top: unset !important;
  }
</style>
