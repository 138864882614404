<template>
  <div role="main" class="main">
    <section class="common-page with-footer speaker-room-page">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-5">
            <div class="row">
              <div class="col-12">
                <div class="border-l text-left">
                  <p v-if="event_info">
                    LIVESTREAM SERVICE STARTS<br />
                    ON {{ event_info.date }} <br />
                    {{ event_info.time }}<br />
                    {{ event_info.time_zone }}
                  </p>
                </div>
              </div>
              <div class="col-md-10">
                <div class="text-left">
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    @click="goLive()"
                  >
                    View Live Stream
                  </button>
                </div>
                <div
                  class="img-box pointer"
                  @click="goNext('shop')"
                  v-if="event_info != null && event_info.flowershop_url"
                >
                  <img
                    :src="require('@/assets/img/order-to-funeral.png')"
                    class="img-fluid float-left mr-2"
                  />
                  <label class="text-primary"
                    >Send flowers to funeral home</label
                  >
                </div>
                <div
                  class="img-box pointer"
                  @click="goNext('donation')"
                  v-if="event_info != null && event_info.donation_url"
                >
                  <img
                    :src="require('@/assets/img/DonateWithPaypal.png')"
                    class="img-fluid float-left mr-2"
                  />
                  <label class="text-primary"
                    >Gift or Donate with<br />
                    <b>PayPal</b>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-7" v-if="event_info != null">
            <div class="c-scroll">
              <div v-for="(input, k) in imagesArray" :key="k" class="mb-3">
                <img
                  :src="input.program_url"
                  class="img-fluid c-img-scoll"
                  @click="openImage(input.program_url)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="container">
      <div class="row align-items-end">
        <div class="col-md-4">
          <label class="text-primary"
            >Type condolences message and press send</label
          >
          <div class="form-group">
            <!--textarea class="form-control" rows="3" placeholder="Please leave a personal mesagge or a memory of our loved Susie here" v-model="message"></textarea> -->
            <VueEmoji
              ref="emoji"
              @input="onInput"
              :value="myText"
              height="100"
            />
          </div>
        </div>
        <div class="col-md-2 pl-lg-0">
          <div class="form-group">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="goNext('condolence')"
            >
              Send your condolences
            </button>
          </div>
        </div>
        <div class="col-md-3 p-md-1 border-left">
          <div
            class="img-box mt-0 form-group pointer"
            @click="goNext('calendar')"
            v-if="event_info != null && event_info.reminder_url"
          >
            <img
              :src="require('@/assets/img/calendar.png')"
              class="img-fluid float-left mr-2 w-50"
            />
            <label class="text-primary">Send me a Calendar Reminder </label>
          </div>
        </div>
        <div class="col-md-3 p-md-1">
          <div
            class="img-box mt-0 form-group pointer"
            @click="goNext('upload_photo')"
          >
            <img
              :src="require('@/assets/img/upload-photo.png')"
              class="img-fluid float-left mr-2 w-50"
            />
            <label class="text-primary"
              >Upload<br />
              Your<br />
              Photos
            </label>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl, liveBaseUrl } from "../../constants/config";
import VueEmoji from "emoji-vue";

export default {
  data() {
    return {
      event_id: 0,
      event_info: null,
      visitor_id: 0,
      message: "",
      event: "",
      checkLive: null,
      connection: null,
      myText: "",
      imagesArray: [],
    };
  },
  mounted() {
    this.event_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).event_id
      : 0;
    this.visitor_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).token
      : 0;
    getApiManager()
      .post(`${apiBaseUrl}/api/visitor/profile/get-info`, {
        visitor_id: this.visitor_id,
      })
      .then((resss) => {
        if (resss.data.error == "visitor_not_exist") {
          localStorage.removeItem("loginInfo");
          this.$router.push("/visitor/home/MTE=");
        }
      });
    getApiManager()
      .post(`${apiBaseUrl}/api/visitor/profile/get-event`, {
        event_id: this.event_id,
      })
      .then((response) => {
        let res = response.data;
        if (res.status === true) {
          this.event_info = res.detail;
          getApiManager()
            .post(`${apiBaseUrl}/api/admin/event/getuploadImagebyEventId`, {
              event_id: this.event_id,
            })
            .then((response) => {
              this.imagesArray = response.data.detail;
              console.log("&&&&&&&&&&", this.imagesArray);
              if (
                this.imagesArray &&
                this.imagesArray &&
                this.imagesArray.length > 0
              ) {
                for (var i = 0; i < this.imagesArray.length; i++) {
                  this.imagesArray[i].program_url =
                    `${apiBaseUrl}` + "/" + this.imagesArray[i].program_url;
                }
              }
            });
          const formateddate = new Date(this.event_info.start_date_time);
          const firstdayMonth = formateddate.getMonth() + 1;
          const firstdayDay = formateddate.getDate();
          const firstdayYear = formateddate.getFullYear();
          const time = new Date(
            this.event_info.start_date_time
          ).toLocaleTimeString("en", {
            timeStyle: "short",
            hour12: true,
            timeZone: this.event_info.time_zone,
          });
          this.event_info.start_date_time =
            ("0000" + firstdayYear.toString()).slice(-4) +
            "-" +
            ("00" + firstdayMonth.toString()).slice(-2) +
            "-" +
            ("00" + firstdayDay.toString()).slice(-2) +
            " " +
            time;
          this.event_info.date = this.event_info.start_date_time.split(" ")[0];
          this.event_info.time =
            this.event_info.start_date_time.split(" ")[1] +
            " " +
            this.event_info.start_date_time.split(" ")[2];
          // this.event_info.program_url =
          //   `${apiBaseUrl}/` + this.event_info.program_url;
          if (res.detail.status == 1) {
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push(
              "/visitor/wait/" + this.event_id + "?x=" + randomnumber
            );
          } else if (res.detail.status == 2) {
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push(
              "/visitor/live/" + this.event_id + "?x=" + randomnumber
            );
          } else if (res.detail.status == 3) {
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push(
              "/visitor/parting/" + this.event_id + "?x=" + randomnumber
            );
          } else {
            this.connectSocket();
          }
        } else {
          this.$notify({
            group: "foo",
            type: "warn",
            title: "Error",
            text: "Server Error",
            animation_type: "slide",
          });
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // this.checkLive = setInterval(() => {
    //   this.goLive()
    // }, 1000*30);
  },
  methods: {
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function(event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        if (data.type == "change_order") {
          console.log("DASHBOARD");
          ref.receiveMoveLive(data.order);
        } else if (data.type == "user_info") {
          ref.connection.send(
            JSON.stringify({
              type: "my_info",
              event_id: ref.event_id,
            })
          );
        } else if (data.type == "start_count") {
          console.log("DASHBOARD");
          ref.receiveMoveLive(data.order);
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    receiveMoveLive(order) {
      this.connection.close();
      console.log("DASHBOARD", this.$route.fullPath);
      if (order == 1) {
        if (this.$route.fullPath != "/visitor/wait") {
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          this.$router.push(
            "/visitor/wait/" + this.event_id + "?x=" + randomnumber
          );
        }
      } else if (order == 2) {
        if (this.$route.fullPath != "/visitor/live") {
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          this.$router.push(
            "/visitor/live/" + this.event_id + "?x=" + randomnumber
          );
        }
      }
    },
    async openImage(imageurl) {
      window.open(imageurl, "_blank");
    },
    onInput(event) {
      this.message = event.data;
    },
    clearTextarea() {
      this.$refs.emoji.clear();
    },
    goNext(page) {
      if (page == "condolence") {
        if (this.message == "") {
          this.$notify({
            group: "foo",
            type: "warn",
            title: "Waring",
            text: "Please input the message.",
            animation_type: "slide",
          });
          return;
        }
        getApiManager()
          .post(`${apiBaseUrl}/api/visitor/condolence/create`, {
            event_id: this.event_id,
            message: this.message,
            visitor_id: this.visitor_id,
          })
          .then((response) => {
            let res = response.data;
            console.log("lll", this.event_id);
            if (res.status === true) {
              this.connection.send(
                JSON.stringify({
                  type: "get_message",
                })
              );
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push(
                "/visitor/condolence/" +
                  res.condolence_id +
                  "/" +
                  this.event_id +
                  "?x=" +
                  randomnumber
              );
            } else {
              this.$notify({
                group: "foo",
                type: "warn",
                title: "Error",
                text: "Server Error",
                animation_type: "slide",
              });
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (page == "upload_photo") {
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/visitor/" + page + "?x=" + randomnumber);
      }
      // else if(page=="shop")
      //   window.open(this.event_info.flowershop_url,"_blank");
      // else if (page=="donation")
      //   window.open(this.event_info.donation_url,"_blank");
      // else if(page=="calendar")
      //   alert("coming soon")
      else if (page == "shop") {
        if (
          this.event_info.flowershop_url.startsWith("http://") ||
          this.event_info.flowershop_url.startsWith("https://")
        ) {
          window.open(this.event_info.flowershop_url, "_blank");
        } else {
          window.open("https://" + this.event_info.flowershop_url, "_blank");
        }
      } else if (page == "donation") {
        if (
          this.event_info.donation_url.startsWith("http://") ||
          this.event_info.donation_url.startsWith("https://")
        ) {
          window.open(this.event_info.donation_url, "_blank");
        } else {
          window.open("https://" + this.event_info.donation_url, "_blank");
        }
      } else if (page == "calendar") {
        window.open(this.event_info.reminder_url, "_blank");
      } else if (page == "voicemail") {
        window.open(this.event_info.tel, "_blank");
      } else {
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/visitor/page/" + page + "?x=" + randomnumber);
      }
    },
    goLive() {
      let ref = this;
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/profile/get-event`, {
          event_id: this.event_id,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.connection.close();
            if (res.detail.status == 1) {
              clearInterval(ref.checkLive);
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push(
                "/visitor/wait/" + this.event_id + "?x=" + randomnumber
              );
            } else if (res.detail.status == 2) {
              clearInterval(ref.checkLive);
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push(
                "/visitor/live/" + this.event_id + "?x=" + randomnumber
              );
            } else {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Error",
                text: "Live Streaming not started yet",
                animation_type: "slide",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    VueEmoji,
  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
.v-window-item {
  min-width: 200px !important;
  width: 350px !important;
  min-height: 400px !important;
  height: 200px !important;
  overflow: hidden;
  border: 5px solid #fff;
}
.banner-name {
  color: #fff;
  font-weight: 400;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px 30px;
  position: relative;
  margin-left: -23px;
  z-index: 1;
  cursor: pointer;
}
.c-col-lg-2 {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}
.c-img-scoll {
  height: 370px;
  object-fit: cover;
}
.c-scroll {
  height: calc(100vh - 400px);
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #772803;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #772803;
}
</style>
